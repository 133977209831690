<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(result)">
          <div class="con_ttl center">
            <span>상품 결제 정보</span>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>사용일</dt>
                <dd v-if="result.acntTransferYn === 'N'">
                  {{ $Util.formatNumber2(result.usePeriod, { unit: '일' }) }}
                </dd>
                <dd v-else>표시없음</dd>
              </dl>
              <dl>
                <dt>적립포인트</dt>
                <dd>
                  {{
                    Number(result.rewardPoint) === 0 ||
                    $Util.isEmpty(result.rewardPoint)
                      ? '이벤트없음'
                      : $Util.formatNumber2(result.rewardPoint, { unit: 'P' })
                  }}
                </dd>
              </dl>
              <dl>
                <dt>판매가</dt>
                <dd v-if="result.acntTransferYn === 'N'">
                  {{
                    $Util.formatNumber2(
                      Number(result.dailyPrice) * Number(result.usePeriod),
                      {
                        unit: '원'
                      }
                    )
                  }}<br />
                  <span class="daily">{{
                    `(${$Util.formatNumber2(result.dailyPrice, {
                      unit: '원'
                    })})`
                  }}</span>
                </dd>
                <dd v-else>
                  {{
                    $Util.formatNumber2(Number(result.dailyPrice), {
                      unit: '원'
                    })
                  }}
                </dd>
              </dl>
              <dl>
                <dt>수수료</dt>
                <dd>{{ $Util.formatNumber2(result.fee, { unit: '원' }) }}</dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>파티명</dt>
                <dd>{{ result.title }}</dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ result.subscriberInfo.name }}</span>
                  <span>{{ result.subscriberInfo.nick }}</span>
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          Number(result.subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                      },
                      {
                        naver:
                          Number(result.subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                      },
                      {
                        google:
                          Number(result.subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                      },
                      {
                        apple:
                          Number(result.subscriberInfo.login_type) ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                      }
                    ]"
                    v-if="!$Util.isEmpty(result.subscriberInfo.login_type)"
                  >
                    {{
                      $ConstCode.getLoginType(result.subscriberInfo.login_type)
                        .enName
                    }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>총 결제액</dt>
                <dd>
                  <span class="sec_color">{{
                    $Util.formatNumber2(result.price, { unit: '' })
                  }}</span>
                  원<span
                    >&nbsp;&nbsp;({{ result.point }}P 사용 / 캐시 할인
                    {{ result.discount }}원)</span
                  >
                </dd>
              </dl>
              <dl>
                <dt>결제 방법</dt>
                <dd>{{ result.paymentType }}</dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ $dayjs(result.paymentDate).format('YYYY.MM.DD') }}</dd>
              </dl>
            </div>
          </div>
          <div v-if="viewResOptions" class="lump">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                파티원 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="result.resKakao" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        v-model="result.resKakao"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="result.resPhone" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요"
                        v-model="result.resPhone"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="result.resEmail" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        v-model="result.resEmail"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="lump" v-if="result.shareYn === 'N'">
            <div class="table_type02">
              <dl>
                <dt>공유 계정</dt>
                <dd v-if="shareConfirm === '0'">
                  <div class="btn_wrap">
                    <button class="btn" @click="fnModalShareInfoCheck">
                      계정 정보 확인
                    </button>
                  </div>
                </dd>
                <dd v-else>
                  <div class="share_info">
                    <dl>
                      <dt>ID</dt>
                      <dd
                        class="copy_button"
                        @click="copyCliboard(shareInfo.shareId, '아이디')"
                      >
                        {{ shareInfo.shareId }}
                      </dd>
                    </dl>
                    <dl>
                      <dt>PW</dt>
                      <dd
                        class="copy_button"
                        @click="copyCliboard(shareInfo.sharePw, '비밀번호')"
                      >
                        {{ shareInfo.sharePw }}
                      </dd>
                    </dl>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump" v-else>
            <div class="table_type02">
              <dl>
                <dt>공유 계정</dt>
                <!--                <dd>
                  <span class="color_secondary">
                    계정정보가 없거나 위즈톡으로 개별 안내 예정입니다. 파티장
                    요청정보를 잘 읽어주세요.
                  </span>
                </dd>-->
                <dd>
                  <span class="color_secondary"
                    >파티장이 위즈톡으로 개별 안내드립니다. 결제완료부터 3시간
                    이내 (심야시간 제외) 안내해 드릴 예정입니다.</span
                  >
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump" v-if="!$Util.isEmpty(result.precautions)">
            <div class="table_type02">
              <dl>
                <dt>파티 소개 글 및 주의 사항</dt>
                <dd class="editor">
                  <span>{{ result.precautions }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <!--210820 수정-->
          <div class="lump bo_zero" v-if="result.optionYn === 'Y'">
            <!--210820 bo_zero 추가-->
            <div class="table_type02">
              <dl>
                <dt>정보 입력</dt>
                <dd class="editor">
                  <div>
                    <!--붙여쓰기-->{{ result.reqOptionMsg
                    }}<!--붙여쓰기-->
                  </div>
                  <div
                    style="white-space: inherit; margin-top: 1rem;"
                    v-show="!$Util.isEmpty(result.reply)"
                  >
                    <label class="input">
                      <input type="text" v-model="result.reply" readonly />
                    </label>
                  </div>
                  <div
                    class="btn_wrap"
                    style="margin-top: 1rem; white-space: unset;"
                  >
                    <button class="btn" @click="fnModalAddInfo">
                      {{
                        $Util.isEmpty(result.reply)
                          ? '추가 정보 등록'
                          : '추가 정보 편집'
                      }}
                    </button>
                  </div>
                  <div class="hint">
                    * 파티장 요청사항이 있는 경우 반드시 정보 입력을 해주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <!--//210820 끝-->
          <div class="con_btn" style="margin-top: 0;">
            <!--            <div class="btn_wrap" v-if="result.paymentType === '위즈캐시'">-->
            <!--              <button class="btn bo" @click="fnCashReceipt">-->
            <!--                현금영수증 신청-->
            <!--              </button>-->
            <!--            </div>-->
            <div class="btn_wrap">
              <button class="btn bo" @click="fnWizzTalk">
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button class="btn" @click="fnConfirm">
                확인
              </button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>주의사항</dt>
                <dd>
                  <!--                                210713삭제-->
                  <!--                                <div>* 위즈니는 사용자간의 컨텐츠 비용을 나눔을 할 수 있도록 지원해드리고 있으며, 참여하신 서비스의 주체가 아닙니다.</div>-->
                  <div>
                    * 개별 공급자가 등록한 나눔 내용 및 거래에 대한 책임은
                    공급자가 부담하며, 이에 따라서 공급자가 진행하는 서비스에
                    대해서 위즈니는 어떠한 책임과 의무를 지지 않습니다.
                  </div>
                  <div>
                    * 파티장 연락두절 및 이용 불가능한 상태 방치 등에 의한
                    환불인 경우 위즈니에서 남은 기간에 대한 환불을 보장하며,
                    위즈캐시로 환불 진행됩니다.
                  </div>
                  <div>
                    (단 참여 후 1일이 경과되지 않은 상태에서 파티장 과실에 의한
                    사용 불가가 확인 된 경우 100%금액 현금 환불)
                  </div>
                  <div class="sec_color">
                    * 양도 거래시에 발생되는 분쟁은 당사자간에 해결이 필요하며,
                    위즈니는 책임을 지지 않습니다. 반드시 계정 양도에 적합한
                    상품인지 확인하고 거래해주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <!--          <div class="caution">-->
          <!--            <div class="inner">-->
          <!--              <dl>-->
          <!--                <dt>유의사항</dt>-->
          <!--                <dd>-->
          <!--                  <div>-->
          <!--                    * 위즈니는 사용자간의 컨텐츠 거래를 지원해드리고 있으며,-->
          <!--                    거래 주체가 아닌 플랫폼 공간을 제공합니다.-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    * 파티장이 등록한 파티 정보 및 거래에 대한 책임은 파티장의-->
          <!--                    몫이며, 이에 따라서 파티장이 진행하는 서비스에 대해서-->
          <!--                    위즈니는 어떠한 책임과 의무를 지지 않습니다.-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    * 파티장 연락두절 및 이용 불가능한 상태 방치 등에 의한-->
          <!--                    환불인 경우 위즈니에서 남은 기간에 대한 환불을 보장합니다.-->
          <!--                    (단 참여 후 1일이 경과되지 않은 상태에서 파티장 과실에 의한-->
          <!--                    사용 불가가 확인 된 경우 100% 금액 환불)-->
          <!--                  </div>-->
          <!--                  <div>-->
          <!--                    캐시 사용 수수료에 대한 현금 영수증은 자동 발행되며, 홈텍스-->
          <!--                    (<a href="https://www.hometax.go.kr" target="_blank"-->
          <!--                      >https://www.hometax.go.kr</a-->
          <!--                    >) 에서 발행내용을 확인하실 수 있습니다-->
          <!--                  </div>-->
          <!--                  <div class="sec_color">-->
          <!--                    * 양도 거래시에 발생되는 분쟁은 당사자간에 해결이 필요하며,-->
          <!--                    위즈니는 책임을 지지 않습니다. 반드시 계정 양도에 적합한-->
          <!--                    상품인지 확인하고 거래해주세요.-->
          <!--                  </div>-->
          <!--                </dd>-->
          <!--              </dl>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'party-done-index',
  setup() {
    return { ...componentState() }
  }
}
// https://localhost:8081/party/done?productNo=940&poid=WIZZNEY20210917QsbbH4-940-20220110082439-1600-13200&productType=PARTY
// https://localhost:8081/party/done?productNo=941&poid=WIZZNEY20210917QsbbH4-941-20220110083823-1000-8000&productType=PARTY 카드결제
</script>

<style scoped></style>
