import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    partyNo: route.query.productNo,
    poid: route.query.poid,
    productType: route.query.productType,
    shareConfirm: '0',
    result: {},
    shareInfo: {},
    viewResOptions: computed(() =>
      proxy.$Util.isEmpty(state.result)
        ? false
        : ['resKakao', 'resPhone', 'resEmail'].some(o => state.result[o])
    )
  })

  const { copyCliboard } = CommonFunction(state)

  const fnResult = async () => {
    const res = await proxy.$CashSvc.postPartyPaymentResult(route.query)
    if (res.resultCode === '0000') {
      state.result = res
    } else {
      alert(res.resultMsg)
    }
  }
  const fnCashReceipt = async () => {
    const params = {}
    params.poid = state.poid
    const res = await proxy.$CashSvc.postRequestCashReceipt(params)
    if (res.resultCode === '0000') {
      alert('현금영수증 신청되었습니다.')
    } else {
      alert(res.resultMsg)
    }
  }
  const fnWizzTalk = async () => {
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$ChatSvc.postGetChatroomId(params)
    if (res.resultCode === '0000') {
      params.chatroomId = res.chatroomId
      await router.push({
        path: '/wizz-talk',
        query: params
      })
    } else {
      alert(res.resultMsg)
    }
  }
  const fnModalAddInfo = async () => {
    // const _callback = reply => {
    //   alert(reply)
    // }
    // https://localhost:8081/party/done?productNo=458&poid=WIZZNEY210331CzDoWv-458-20210907163116-0-1000&productType=PARTY
    const payload = {
      component: proxy.$modalAlertNames.PARTY_ADD_INFO,
      data: state.result,
      callback: async reply => {
        await fnSaveReqOption(reply)
      }
    }
    await fnModalAlert(payload)
  }
  const fnSaveReqOption = async reply => {
    const params = {}
    params.partyNo = state.partyNo
    params.reply = reply
    params.memberSeq = state.result.memberSeq
    let res
    if (proxy.$Util.isEmpty(state.result.reply)) {
      res = await proxy.$MyPageSvc.postPartyOptionRegister(params)
    } else {
      res = await proxy.$MyPageSvc.postPartyOptionUpdate(params)
    }
    if (res.resultCode === '0000') {
      state.result.reply = reply
    } else {
      alert(res.resultMsg)
    }
    console.log('res {}', res)
  }
  const fnConfirm = async () => {
    if (
      state.result.acntTransferYn === 'Y' &&
      state.result.optionYn === 'Y' &&
      proxy.$Util.isEmpty(state.result.reply)
    ) {
      if (
        !confirm(
          '파티장이 요청한 정보 입력을 하지 않았습니다.\n' +
            '다음에 하시겠습니까? 정보입력은 마이페이지> 참여파티에서 다시 입력할 수 있습니다.'
        )
      )
        return
    }
    await router.push({ path: '/party/list' })
  }

  const fnModalShareInfoCheck = async () => {
    const data = {
      partyNo: state.partyNo,
      memberSeq: state.result.memberSeq
    }
    const payload = {
      component: proxy.$modalAlertNames.SHARE_INFO_CHECK,
      data,
      callback: async () => {
        await fnDetail()
      }
    }

    await fnModalAlert(payload)
  }

  const fnDetail = async () => {
    const params = {
      partyNo: state.partyNo
    }
    const res = await proxy.$MyPageSvc.postParticipatePartyDetail(params)
    if (res.resultCode === '0000') {
      state.shareInfo = res.shareInfo
      state.shareConfirm = res.party.shareConfirm
    } else {
      alert(res.resultMsg)
    }
  }

  const init = async () => {
    await fnResult()
  }
  init()
  return {
    ...toRefs(state),
    fnCashReceipt,
    fnWizzTalk,
    fnModalAddInfo,
    fnConfirm,
    copyCliboard,
    fnModalShareInfoCheck
  }
}
